import { Col, Row, Card, Form, CardBody, FormGroup, Label, Button, Spinner } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import SideNav from '../components/sidenav';
import AuthContext from '../contexts/auth';
import React, { useState } from 'react';
import { HeadFC } from 'gatsby';

import './index.scss';

const AdminAjustes = () => {
  const [loading, setLoading] = useState(false);

  const onSubmitRebuildContentForm = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const resolveAfter3Sec = new Promise(resolve => {
      setTimeout(() => {
        setLoading(false);
        resolve(true);
      }, 5000);
    });

    toast.promise(resolveAfter3Sec,
      {
        pending: 'El contenido se está reconstruyendo',
        success: 'El contenido ha sido reconstruido 👌',
        error: 'Error al reconstruir el contenido 🤯'
      });
  }

  return (
    <AuthContext>
      <ToastContainer />
      <div className="admin-container d-flex flex-row w-100">
        <SideNav
          activeMenu='ajustes'
        />
        <Row className="admin-page-content d-flex flex-column align-items-center">
          <Col md={11}>
            <Card className="w-100 p-4 mb-5">
              <CardBody>
                <Form onSubmit={(e) => onSubmitRebuildContentForm(e)}>
                  <h4>Ajustes</h4>

                  <FormGroup>
                    <Label className="mb-2">
                      Reconstruir todo o contenido:
                    </Label>
                    {" "}
                    <Button
                      size="md"
                      color="warning"
                      className="ml-2"
                      disabled={loading}
                    >
                      {loading ? <>
                        <Spinner size="sm"></Spinner>{" "}
                        <span> Espere por favor</span>
                      </> : 'Reconstruir'}
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </AuthContext>
  );
}

export default AdminAjustes;

export const Head: HeadFC = () => <title>LaBotana : Admin - Ajustes</title>
